import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { ShipmentsPrint } from 'ui/component/orders-list/order-print';
import {
    ISalesLayoutConfig,
    salesLayoutConfigVar,
    currentOrderVar,
    shipmentIdVar,
} from '../../layouts/sales-layout/sales-layout-state';
import { salesGuestValidOrderResultVar } from '../../layouts/guest-layout/guest-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { findShipmentByNumber } from '../../util/process-order-entities';
import { OrderDetailsPageContent } from '../order-details-page-content';
import orderDetailsStyles from '../style.css';

const ShipmentPrintPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pageTitle, setPageTitle] = useState<string>('');

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);
    const currentOrder = useReactiveVar(currentOrderVar);
    const shipmentNumber = useReactiveVar(shipmentIdVar);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const guestShipmentPrintPageComponent: JSX.Element = isLoaded ? (
        <OrderDetailsPageContent>
            <ShipmentsPrint config={config} order={currentOrder} shipmentNumber={shipmentNumber} />
        </OrderDetailsPageContent>
    ) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirect = !result && !!redirectUrl.length;

        if (isRedirect) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    useEffect(() => {
        if (!currentOrder) {
            return;
        }

        const {
            shipments,
        } = currentOrder;

        const updatedPageTitle = shipmentNumber !== ''
            ? t('Print Shipment #%1', (findShipmentByNumber(shipments, shipmentNumber)?.number || ''))
            : t('Print All Shipments');
        setPageTitle(updatedPageTitle);
    }, [currentOrder]);

    return (
        <SalesLayout
            pageKey="guest-shipment-print"
            pageTitle={pageTitle}
            pageContent={guestShipmentPrintPageComponent}
            salesLayoutClassName={orderDetailsStyles.printPageLayout}
            loadCart={false}
            loadCountries
            isGuestPage
            loadShipments
            splitOrderItems={false}
        />
    );
};

export { ShipmentPrintPage as default };
